<template>
<!--  <div class="row m-0 mt-md-5 mb-md-5">-->
  <div class="row m-0 mt-md-5 mb-md-5 m-auto" style="width: 88%">
    <div class="col-md-3">
      <div class="card-header text-white pt-5 pb-5 h3 animate__animated animate__slideInDown">关于我们</div>
      <div class="list-group p-0">
        <a href="/company" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">中心简介</a>
        <a href="/leader" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">中心领导</a>
        <a href="/funcDep" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">职能部门</a>
        <a href="/leadership" class="bg-light font-weight-bold text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown active">领导讲话</a>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp" style="min-height: 600px">
      <div class="text-left h3">领导讲话</div>
      <div class="text-left small">
        <span>2022年05月21日</span><span class="pl-md-3 pr-md-3"> / </span><span>Liych</span>
      </div>
      <div class="text-left mt-md-5 w-100 ql-container ql-snow pt-md-3">
        <div class="ql-editor" v-html="idadership.notice"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "leadership",
  data() {
    return {
      idadership: ''
    }
  },
  mounted() {
    this.getIeaderShip(5)
  },
  methods: {
    getIeaderShip(id) {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getCMSNotice/' + id
      }).then((res) =>{
        this.idadership = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>